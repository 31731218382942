import {
    ListHeaderItem, RequestData, getSearch
} from '@/components/common/list';
import { ChangeWordList } from '@/methods/setText';
import { community } from '@/data';
import { SearchKey, LogPageData } from './all.type';

export default null;

function getMenus() {
    const menus = [{
        label: WordList.ProperAllTextDoorLogs,
        key: 'doorLogs',
        path: 'doorLogs'
    }, {
        label: WordList.NavInHtmlMenuCall,
        key: 'callHistory',
        path: 'callHistory'
    }, {
        label: WordList.capture,
        key: 'capture',
        path: 'capture'
    }, {
        label: WordList.ProperAllTextTemperatureLogs,
        key: 'temperatureLogs',
        path: 'temperatureLogs'
    }, {
        label: WordList.ProperAllTextMotionAlerts,
        key: 'motionAlerts',
        path: 'motionAlerts'
    }];

    if (!community.enableShowLog.value) {
        menus.splice(0, 3);
    }

    return menus;
}

const getLogPageData = (projectType: ProjectType | false = false) => {
    // list header 数据
    const headerDoorLogs: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'captureTime'
        }, {
            label: ChangeWordList.PMSetAccessBuildOrDepartment,
            name: 'buildingName'
        }, {
            label: WordList.DeviceLocation,
            name: 'deviceName',
            type: 'customize'
        }, {
            label: WordList.CaptureListTanleInitiator,
            name: 'initiator',
            type: 'customize'
        }, {
            label: WordList.RDeviceSearchLabelRoomName,
            name: 'roomNum',
            type: 'customize'
        }, {
            label: WordList.LogInHtmlType,
            name: 'doorLogType',
            type: 'customize'
        }, {
            label: WordList.CaptureListTanleAction,
            name: 'action',
            type: 'customize'

        }, {
            label: WordList.TmpKeyListTanleKey,
            name: 'key'
        }, {
            label: WordList.Response,
            name: 'response',
            type: 'customize'
        }, {
            label: WordList.capture,
            name: 'smallPicUrl',
            type: 'customize'
        }
    ];
    // 办公隐藏apt列
    if (projectType === 'office') {
        headerDoorLogs.splice(4, 1);
    }
    const headerCallHistory: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'startTime'
        }, {
            label: WordList.RecodeListTanleCallerName,
            name: 'callerName'
        }, {
            label: WordList.TabelMessageBoxReceiver,
            name: 'calleeName'
        }, {
            label: WordList.ProperAllTextCallDuration,
            name: 'isAnswer',
            type: 'customize'
        }
    ];
    const headerCapture: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'captureTime'
        }, {
            label: WordList.TabelUpdateBoxDevice,
            name: 'deviceName'
        }, {
            label: WordList.CaptureListTanleInitiator,
            name: 'initiator'
        }, {
            label: WordList.CaptureListTanleAction,
            name: 'captureType',
            type: 'customize'
        }, {
            label: WordList.capture,
            name: 'smallPicUrl',
            type: 'customize'
        }
    ];
    const headerTemperatureLogs: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'captureTime'
        }, {
            label: WordList.ProperAllTextBodyTemperature,
            name: 'unit',
            type: 'customize'
        }, {
            label: WordList.MulListUpdateListTanleStatus,
            name: 'status',
            type: 'customize'
        }, {
            label: WordList.TabelUpdateBoxDevice,
            name: 'deviceName'
        }, {
            label: WordList.capture,
            name: 'smallPicUrl',
            type: 'customize'
        }
    ];
    const headerMotionAlerts: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'captureTime'
        }, {
            label: ChangeWordList.PMSetAccessBuildOrDepartment,
            name: 'buildingName'
        }, {
            label: WordList.TabelUpdateBoxDevice,
            name: 'deviceName'
        }, {
            label: WordList.capture,
            name: 'smallPicUrl',
            type: 'customize'
        }
    ];
    const headerAuditLogs: Array<ListHeaderItem> = [
        {
            label: WordList.CaptureListTanleCaptureTime,
            name: 'CreateTime'
        }, {
            label: WordList.CaptureListTanleInitiator,
            name: 'Operator'
        }, {
            label: WordList.TabelKeyInHtmlType,
            name: 'Type'
        }, {
            label: WordList.CaptureListTanleAction,
            name: 'Action'
        }
        // , {
        //     label: WordList.ProperAllTextObject,
        //     name: ''
        // }
    ];

    // list requestData 数据
    const doorLogsRequestData: RequestData = {
        url: 'v4/web/office/log/pm/getDoorLog',
        param: getSearch({
            buildingId: '',
            doorLogType: '',
            startTime: '',
            endTime: '',
            searchKey: 'device',
            searchValue: '',
            action: '',
            key: ''
        })
    };
    const callHistoryRequestData: RequestData = {
        url: 'v4/web/office/log/pm/getCallLog',
        param: getSearch(
            {
                startTime: '',
                endTime: '',
                nameSearchValue: ''
            }
        )
    };
    const captureRequestData: RequestData = {
        url: 'v4/web/office/log/pm/getCaptureLog',
        param: getSearch({
            startTime: '',
            endTime: ''
        })
    };
    const temperatureLogsRequestData: RequestData = {
        url: 'v4/web/office/log/pm/getTemperatureLog',
        param: getSearch({
            startTime: '',
            endTime: '',
            status: '',
            unit: 'fahrenheit'
        })
    };
    const motionAlertsRequestData: RequestData = {
        url: 'v4/web/office/log/pm/getMotionLog',
        param: getSearch({
            startTime: '',
            endTime: ''
        })
    };
    const auditLogsRequestData: RequestData = {
        url: 'v3/web/community/audit/getList',
        param: getSearch({
            Type: 'all',
            BeginTime: '',
            EndTime: '',
            UserType: 'all'
        })
    };

    const logPageData: LogPageData = {
        doorLogs: {
            header: headerDoorLogs,
            requestData: doorLogsRequestData,
            action: false
        },
        callHistory: {
            header: headerCallHistory,
            requestData: callHistoryRequestData,
            action: false
        },
        capture: {
            header: headerCapture,
            requestData: captureRequestData,
            action: false
        },
        temperatureLogs: {
            header: headerTemperatureLogs,
            requestData: temperatureLogsRequestData,
            action: false
        },
        motionAlerts: {
            header: headerMotionAlerts,
            requestData: motionAlertsRequestData,
            showCheck: true,
            defaultAction: [],
            deleteUrl: 'v4/web/office/log/pm/deleteMotionLog',
            action: []
        },
        auditLogs: {
            header: headerAuditLogs,
            requestData: auditLogsRequestData,
            action: false
        }
    };
    return logPageData;
};

const LogTypeEnum = {
    doorLogs: 1,
    callHistory: 4,
    temperatureLogs: 3,
    capture: 2
};

export {
    getMenus,
    getLogPageData,
    LogTypeEnum
};
